"use client";
import styled from "@emotion/styled";
import { Popup } from "../../components/popup";
import {
  LOGIN_URL,
  CONTACT_URL,
  TEAM_URL,
  FLOWN_SCIENCE_URL,
  FLOWN_STORY_URL,
  REGISTER_URL,
  HOME_URL,
  TESTIMONIALS_URL,
  FLOWN_IN_PRESS_URL,
  FLOWN_FOR_BUSINESS_URL,
  FACILITATORS_URL,
  BLOG_URL,
  PRICING_URL,
  MEMBERSHIP_BENEFITS_URL,
  HOW_IT_WORKS_URL,
  FAQS_URL,
  SSO_LOGOUT_URL,
  FULL_CALENDAR_URL,
  FLOWN_FOR_ADHD_URL,
  FLOWN_FOR_REMOTE_WORKERS_URL,
  FLOWN_FOR_STUDENTS_URL,
  UNIVERSITIES_URL,
} from "utils/urls";

import { NavMenuContainer } from "./nav-styles";
import React, { useEffect, useState } from "react";
import { ProfilePopupMenu } from "./profile-popup-menu";
import { logOut } from "features/auth";
import { getLoggedInProfileMenuItems } from "./nav-menu-utils";
import { useUserContext } from "context/user-context";
import { Box, BoxCol, Button, Row } from "design-system/components";
import { Accordion } from "design-system/components";
import Image from "next/image";
import { Icon } from "design-system/components";
import Link from "next/link";
import { getIsAuthorizedRole } from "services/roles/user-roles";
import { useRouter } from "next/router";
import { QUERY_PARAMS, getStringifiedQuery } from "utils/url-query-utils";
import { css } from "styled-system/css";
import { Modal } from "design-system/organisms/modals";

type isAuthorizedProps = {
  isAuthorizedRole: boolean;
};

const DESKTOP_NAV_POPUP_OPTIONS = {
  closeOnDocumentClick: true,
  closeOnEscape: true,
  mouseLeaveDelay: 100,
  mouseEnterDelay: 0,
  offsetY: 10,
  arrow: false,
};

/**
 * This is the navigation that is used for the marketing pages of the site.
 * It will have some slight differences for logged in users and allows them
 * to navigate back into the app. The following user roles will see this menu
 * - ANONYMOUS, REGISTERED, SUBSCRIBED (but not verified)
 */
export const MarketingNavMenu = () => {
  const { loggedIn, userRole } = useUserContext();
  const { asPath, query } = useRouter();

  const [currentPage, setCurrentPage] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const onLogout = async () => {
    await logOut();
  };

  useEffect(() => {
    // We use this to add a param to the register link so we know which page we
    // came from so can track on the server.  Helps with campaigns.
    setCurrentPage(asPath);
  }, [asPath]);

  return (
    <NavMenuContainer data-component="MarketingNavMenu">
      <NavInnerContainer>
        <Box className="flex gap-32 align-center">
          <FlownLogo />
          <Popup
            trigger={
              <TooltipMenuTrigger style={{ cursor: "default" }}>
                What we offer <Icon className="mar-x-8" icon="chevron-down" />
              </TooltipMenuTrigger>
            }
            on={["hover", "click"]}
            {...DESKTOP_NAV_POPUP_OPTIONS}
          >
            {
              <ToolTipMenu tabIndex={0}>
                <WhatWeOfferContent
                  isAuthorizedRole={getIsAuthorizedRole(userRole)}
                />
              </ToolTipMenu>
            }
          </Popup>
          <Popup
            trigger={
              <TooltipMenuTrigger style={{ cursor: "default" }}>
                Who's it for? <Icon className="mar-x-8" icon="chevron-down" />
              </TooltipMenuTrigger>
            }
            on={["hover", "click"]}
            {...DESKTOP_NAV_POPUP_OPTIONS}
          >
            {
              <ToolTipMenu tabIndex={0}>
                <WhosItForContent />
              </ToolTipMenu>
            }
          </Popup>
        </Box>
        <Box className="flex gap-16 align-center">
          <LinkContainer href={BLOG_URL}>Blog</LinkContainer>
          <Popup
            trigger={
              <TooltipMenuTrigger style={{ cursor: "default" }}>
                About us
              </TooltipMenuTrigger>
            }
            on={["hover", "click"]}
            {...DESKTOP_NAV_POPUP_OPTIONS}
          >
            {
              <ToolTipMenu tabIndex={0}>
                <AboutUsButtons />
              </ToolTipMenu>
            }
          </Popup>
          <Box
            className={css({
              display: "none",
              "bp-desktop-xs": {
                display: "flex",
                alignItems: "center",
              },
            })}
          >
            {!loggedIn ? (
              <>
                <LinkContainer href={LOGIN_URL}>Log in</LinkContainer>
                <Button
                  as="a"
                  variant="primary-green"
                  href={
                    REGISTER_URL +
                    `?${QUERY_PARAMS.REFER_PAGE}=${currentPage}${
                      query ? `${getStringifiedQuery(query)}` : ""
                    }`
                  }
                >
                  Join us
                </Button>
              </>
            ) : (
              <ProfilePopupMenu />
            )}
          </Box>
          <Box onClick={() => setMenuOpen(true)} className="cursor-pointer">
            <BurgerMenuIcon icon="burger-menu" />
          </Box>
          <Modal
            title=""
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            contentStyle={{ height: "100dvh" }}
          >
            <DropdownMenuOuterContainer>
              <DropDownMenuContainer>
                <Row className="justify-between" paddingBottom={"24"}>
                  <FlownLogo
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  />
                  <Icon
                    css={(theme) => ({
                      marginRight: theme.spacing[12],
                    })}
                    icon="close"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  />
                </Row>
                <Accordion
                  type="multiple"
                  items={[
                    {
                      title: "What we offer",
                      content: (
                        <AccordionContentContainer
                          onClick={() => {
                            setMenuOpen(false);
                          }}
                        >
                          <WhatWeOfferContent
                            isAuthorizedRole={getIsAuthorizedRole(userRole)}
                          />
                        </AccordionContentContainer>
                      ),
                    },
                    {
                      title: "Who's it for?",
                      content: (
                        <AccordionContentContainer
                          onClick={() => {
                            setMenuOpen(false);
                          }}
                        >
                          <WhosItForContent />
                        </AccordionContentContainer>
                      ),
                    },
                    {
                      title: "About us",
                      content: (
                        <AccordionContentContainer
                          onClick={() => {
                            setMenuOpen(false);
                          }}
                        >
                          <AboutUsButtons />
                        </AccordionContentContainer>
                      ),
                    },
                  ]}
                />
                {loggedIn ? (
                  <Box className="mar-y-16">
                    {getLoggedInProfileMenuItems(userRole).map(
                      (menuItem, index) => (
                        <LinkContainer
                          href={menuItem.url}
                          key={index}
                          data-cy={menuItem.dataCy}
                        >
                          {menuItem.item}
                        </LinkContainer>
                      )
                    )}

                    <LinkContainer
                      as="a"
                      href={SSO_LOGOUT_URL}
                      onClick={onLogout}
                    >
                      Log out
                    </LinkContainer>
                  </Box>
                ) : (
                  <>
                    <BoxCol px="16" py="24" gap="16">
                      <Button
                        as="a"
                        variant="ghost"
                        href={LOGIN_URL}
                        full
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                      >
                        Log in
                      </Button>
                      <Button
                        as="a"
                        variant="primary"
                        href={REGISTER_URL}
                        full
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                      >
                        Join us
                      </Button>
                    </BoxCol>
                  </>
                )}
              </DropDownMenuContainer>
            </DropdownMenuOuterContainer>
          </Modal>
        </Box>
      </NavInnerContainer>
    </NavMenuContainer>
  );
};

const AccordionContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

const FlownLogo = (props: { onClick?: () => void }) => (
  <Link href={HOME_URL} onClick={props.onClick}>
    <StyledLogoImage
      src={"/assets/shared/flown-logo-black.svg"}
      width={150}
      height={40}
      style={{
        filter: "drop-shadow(0 0 10px #fff) drop-shadow(0 0 20px #fff)",
        objectFit: "contain",
      }}
      alt="Flown logo"
    />
  </Link>
);

const WhatWeOfferContent: React.FC<isAuthorizedProps> = () => (
  <>
    <LinkContainer href={MEMBERSHIP_BENEFITS_URL}>
      What's included
    </LinkContainer>
    <LinkContainer href={HOW_IT_WORKS_URL}>
      How focus sessions work
    </LinkContainer>
    <LinkContainer href={FULL_CALENDAR_URL}>
      Focus session calendar
    </LinkContainer>
    <LinkContainer href={TESTIMONIALS_URL}>What our members say</LinkContainer>
    <LinkContainer href={PRICING_URL}>Pricing</LinkContainer>
    <LinkContainer href={FAQS_URL}>FAQs</LinkContainer>
  </>
);

const WhosItForContent = () => (
  <>
    <LinkContainer href={FLOWN_FOR_REMOTE_WORKERS_URL}>
      Remote/hybrid workers
    </LinkContainer>
    <LinkContainer href={FLOWN_FOR_ADHD_URL}>
      ADHD and neurodiverse
    </LinkContainer>
    <LinkContainer href={FLOWN_FOR_STUDENTS_URL}>PhDs and grads</LinkContainer>
    <LinkContainer href={UNIVERSITIES_URL}>Universities</LinkContainer>
    <LinkContainer href={FLOWN_FOR_BUSINESS_URL}>Businesses</LinkContainer>
  </>
);

const AboutUsButtons = () => (
  <>
    <LinkContainer href={FLOWN_STORY_URL}>The FLOWN story</LinkContainer>
    <LinkContainer href={FLOWN_SCIENCE_URL}>
      The science behind FLOWN
    </LinkContainer>
    <LinkContainer href={FLOWN_IN_PRESS_URL}>What the press says</LinkContainer>
    <LinkContainer href={TEAM_URL}>Our team</LinkContainer>
    <LinkContainer href={FACILITATORS_URL}>Our facilitators</LinkContainer>
    <LinkContainer href={CONTACT_URL}>Contact us</LinkContainer>
  </>
);

const NavInnerContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: theme.spacing[16],
  justifyContent: "space-between",
  margin: "0 auto",
  maxWidth: theme.maxWidth.content,
  width: "100%",
}));

const BurgerMenuIcon = styled(Icon)`
  display: block;
  filter: drop-shadow(0 0 20px #fff) drop-shadow(0 0 20px #fff)
    drop-shadow(0 0 10px #fff);
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    display: none;
  }
`;

const DropdownMenuOuterContainer = styled.div`
  background: transparent;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
`;

const DropDownMenuContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 3;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing[16]};
  padding-bottom: 5rem;
`;

const TooltipMenuTrigger = styled.button`
  display: none;
  ${({ theme }) => theme.media["bp-desktop-xs"]} {
    display: flex;
    align-items: center;
  }
  :focus {
    box-shadow: none;
  }
`;

const ToolTipMenu = styled.div`
  min-width: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  outline: none;
  z-index: 999;
  border: solid 1px #e5e1e6;
  border-radius: 6px;
  box-shadow: none;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: ${({ theme }) => theme.spacing[8]};
`;

const LinkContainer = styled(Link)`
  align-items: center;
  border-radius: ${({ theme }) => theme.radii[12]};
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing[16]};
  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.grey100};
  }
`;

const StyledLogoImage = styled(Image)(({ theme }) => ({
  width: "150px",

  [theme.media["bp-tablet"]]: {
    width: "100px",
  },
  [theme.media["bp-desktop-xs"]]: {
    width: "150px",
  },
}));
